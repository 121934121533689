import React from "react";
import { Link } from "gatsby";
import { FooterMenuWrap, FooterMenuList } from "./footer-menu.stc";

const FooterMenu = () => {
  return (
    <FooterMenuWrap>
      <FooterMenuList>
        <Link to="/privacy-policy" className="no-cursor">
          Privacy 
        </Link>
      </FooterMenuList>
      {/* <FooterMenuList>
        <Link to="/" className="no-cursor">
          Cookies
        </Link>
      </FooterMenuList> */}
      <FooterMenuList>
        <Link to="/project" className="no-cursor">
          CASE STUDIES
        </Link>
      </FooterMenuList>
      <FooterMenuList>
        <Link to="/sitemap" className="no-cursor">
          Sitemap
        </Link>
      </FooterMenuList>
      <FooterMenuList>
        <Link to="/company" className="no-cursor">
          ABOUT
        </Link>
      </FooterMenuList>
      <FooterMenuList>
        <Link to="/careers" className="no-cursor">
          Careers
        </Link>
      </FooterMenuList>
    </FooterMenuWrap>
  );
};

export default FooterMenu;
